export default function SquareToggle({ className, color }) {
  return (
    <svg
      className={className}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="8.48535"
        width="12"
        height="12"
        transform="rotate(-45 0 8.48535)"
        fill={color}
      />
    </svg>
  );
}
