import { Link, Route, Routes } from "react-router-dom";
import "../blocks/index.css";
import Home from "./Home/Home";
import About from "./About/About";
import Experience from "./Experience/Experience";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Footer from "./Footer/Footer";
import LoadingScreen from "./Loading-screen/loading-screen";
import { useEffect, useState } from "react";
import NavBar from "../components/havigation-bar/NavBar";
import { projectsArr } from "../utils/constants";
import _ from "lodash";

function App() {
  const [theme, setTheme] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (prefersDark) {
      setTheme(true);
    } else {
      setTheme(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, 2000);
  }, []);

  return (
    <div
      className="page"
      style={
        theme
          ? { background: "var(--dark-background)", color: "white" }
          : { background: "var(--light-background)", color: "black" }
      }
    >
      {projectsArr.map((project) => {
        return <Link key={_.uniqueId()} to={"projects/" + project.id} />;
      })}
      <LoadingScreen visibility={isVisible} mode={theme} />
      <Routes>
        <Route
          path="/"
          element={<Home modeState={theme} setMode={setTheme} />}
        />
        <Route path="/about" element={<About modeState={theme} />} />
        <Route path="/experience" element={<Experience modeState={theme} />} />
        <Route path="/skills" element={<Skills modeState={theme} />} />
        <Route path="/projects" element={<Projects modeState={theme} />} />
        <Route path="/contact" element={<Footer modeState={theme} />} />
      </Routes>
      <NavBar theme={theme} setTheme={setTheme} />
    </div>
  );
}

export default App;
