import { useEffect, useRef } from "react";
import Triangle from "../icons/toggle-icon";
import SquareToggle from "../icons/toggle-icon-right";

export default function ToggleSwitch({ mode, setMode }) {
  const circleRef = useRef();

  useEffect(() => {
    if (mode) {
      circleRef.current.style.left = "2px";
      circleRef.current.style.transform = "translateX(0)";
    } else {
      circleRef.current.style.transform = "translateX(46px)";
    }
  }, [mode]);

  return (
    <div
      className="toggle-switch"
      onClick={() => {
        setMode(!mode);
      }}
      style={
        mode ? { border: "2px solid #fff" } : { border: "2px solid #1E1E1E" }
      }
    >
      <div
        className="toggle-switch__circle"
        ref={circleRef}
        style={mode ? { background: "#fff" } : { background: "#1E1E1E" }}
      />
      <div className="toggle-switch__left">
        <Triangle className={"toggle-switch__icon"} color={"#FF3737"} />
      </div>
      <div className="toggle-switch__right">
        <SquareToggle className={"toggle-switch__icon"} color={"#00BFFF"} />
      </div>
    </div>
  );
}
