export default function LinkedinIcon({ className, color }) {
  return (
    <svg
      className={className}
      width="107"
      height="100"
      viewBox="0 0 107 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9567 0C5.88506 0 0.0227159 4.83704 0.0227159 11.4897C0.0227159 18.1656 6.02462 23.2157 13.9567 23.2157C22.0283 23.2157 27.8906 18.2852 27.8906 11.3534C27.5184 4.67748 21.7724 0 13.9567 0ZM2.32611 27.8906C1.04675 27.8906 0 28.9373 0 30.2167V97.6739C0 98.9532 1.04675 100 2.32611 100H25.5872C26.8666 100 27.9133 98.9532 27.9133 97.6739V30.2167C27.9133 28.9373 26.8666 27.8906 25.5872 27.8906H2.32611ZM37.2178 27.8906C35.9384 27.8906 34.8916 28.9373 34.8916 30.2167V97.6739C34.8916 98.9532 35.9384 100 37.2178 100H58.1527C59.4368 100 60.4789 98.9579 60.4789 97.6739V62.7822V62.2007V61.6192C60.4789 56.1063 64.7138 51.6158 70.1104 51.1971C70.3895 51.1506 70.6672 51.1517 70.9463 51.1517C71.2255 51.1517 71.5032 51.1506 71.7823 51.1971C77.1789 51.6158 81.4138 56.1063 81.4138 61.6192V97.6739C81.4138 98.9579 82.4559 100 83.7399 100H104.675C105.954 100 107.001 98.9532 107.001 97.6739V58.13C107.001 43.0801 98.9273 27.8906 80.9232 27.8906C72.7353 27.8906 66.55 31.0547 62.805 33.6832V30.2167C62.805 28.9373 61.7582 27.8906 60.4789 27.8906H37.2178Z"
        fill={color}
      />
    </svg>
  );
}
