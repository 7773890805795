export default function SkillsCard({ image, name, theme }) {
  return (
    <button
      className="skills-card"
      type="button"
      style={
        theme
          ? { border: "2px solid white", color: "white" }
          : {
              border: "2px solid black",
              color: "black",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }
      }
    >
      <img className="skills-card__image" src={image} alt={`${name} icon`} />
      <p className="skills-card__name">{name}</p>
    </button>
  );
}
