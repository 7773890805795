import { useEffect } from "react";
import CloseIcon from "../icons/close-icon";

export default function Modal({ isOpen, close, children, modeState }) {
  useEffect(() => {
    if (!isOpen) return;

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [isOpen, close]);

  return (
    <div
      className="modal__overlay"
      style={
        isOpen
          ? { opacity: "1", visibility: "visible" }
          : { opacity: "0", visibility: "hidden" }
      }
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          close();
        }
      }}
    >
      <div className={`modal ${modeState ? "modal_dark" : "modal_light"}`}>
        {children}

        <CloseIcon
          className={`modal__close-button ${modeState && "modal__close-dark"}`}
          width="40px"
          height="40px"
          onClickFunction={() => {
            close();
          }}
        />
      </div>
    </div>
  );
}
