export default function ProjectCard({
  id,
  image,
  title,
  description,
  tech,
  projectUrl,
  githubUrl,
  index,
  mode,
  clickFunction,
  setData,
}) {
  function handleDataSet() {
    setData({
      image,
      title,
      description,
      tech,
      projectUrl,
      githubUrl,
      index,
    });
  }

  return (
    <li
      className="project-card"
      onClick={() => {
        clickFunction();

        handleDataSet();
      }}
    >
      <div
        className={`project-card__fade ${
          mode ? "project-card__dark-theme" : "project-card__light-theme"
        }`}
      />
      <div
        className="project-card__content"
        style={{
          backgroundImage: `url("${image}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <div className="project-card__overlay" />
    </li>
  );
}
