export default function Triangle({ className, color }) {
  return (
    <svg
      className={className}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.5 0L12.9952 11.25H0.00480938L6.5 0Z" fill={color} />
    </svg>
  );
}
