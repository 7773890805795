import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../toggle-switch/ToggleSwitch";

function NavItems({
  navItemsList,
  setClose,
  isClose,
  menuRef,
  className,
  theme,
  setTheme,
}) {
  const navigate = useNavigate();

  return (
    <ul
      className={`header__nav nav ${theme ? "nav_light" : "nav_dark"}`}
      style={
        isClose
          ? { transform: "translateY(0)" }
          : { transform: "translateY(-100%)" }
      }
    >
      <ToggleSwitch mode={theme} setMode={setTheme} />
      {navItemsList.map((item, index) => {
        return (
          <li
            className={className}
            key={index}
            onClick={() => {
              navigate(item.link);
              setClose(false);
              menuRef.current.checked = false;
            }}
          >
            {item.name}
          </li>
        );
      })}
    </ul>
  );
}

export default NavItems;
