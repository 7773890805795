import { useState } from "react";

export default function ExperienceCard({
  title,
  type,
  position,
  subtitle,
  list,
  index,
  mode,
}) {
  const [screenWidth, setScreenWidth] = useState(window.screen.availWidth);

  window.addEventListener("resize", function () {
    setScreenWidth(window.screen.availWidth);
  });

  return (
    <div
      className="experince__card experience-card"
      style={
        index % 2 === 0 && screenWidth > 860
          ? { margin: " 0 0.5% 0 auto" }
          : { margin: 0 }
      }
    >
      <div
        className="experience-card__container"
        style={
          mode ? { border: "3px solid #ffffff" } : { border: "3px solid #000" }
        }
      >
        <div
          className="experience-card__header"
          style={
            mode
              ? { borderBottom: "2px solid white" }
              : { borderBottom: "2px solid black" }
          }
        >
          <h3
            className={`experience-card__title ${
              mode
                ? "experience-card__title_red"
                : "experience-card__title_yellow"
            }`}
          >
            {title} <span className="experience-card__type">{type}</span>
          </h3>
          <p className="experience-card__position">{position}</p>
        </div>
        <p className="expereince-card__content">{subtitle}</p>
        <ul className="experience-card__content">
          {Array.from(list).map((work, index) => {
            return (
              <li key={index} className="experience-card__content">
                {work}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
