import FooterLink from "../../components/footer-link/footer-link";
import { footerLinks } from "../../utils/constants";

export default function Footer({ modeState }) {
  const date = new Date();

  return (
    <footer className="footer">
      <h2
        className={`footer__title footer__content ${
          modeState ? "footer__title_red" : "footer__title_purple"
        }`}
      >
        Don't be a stranger, say hello!
      </h2>
      <p className="footer__text footer__content">
        Text me if you want to team up or just leave a feedback about my site.
        I’m always looking for some new connections.
      </p>
      <ul className="footer__links footer__content">
        {footerLinks.map((link, index) => {
          return (
            <FooterLink
              key={index}
              Icon={link.Icon}
              name={link.name}
              email={link.email}
              url={link.url}
              mode={modeState}
            />
          );
        })}
      </ul>
      <p className="footer__copyright footer__content">
        © Designed and created by Kamal Ganiev
      </p>
      <p className="footer__date footer__content">{date.getFullYear()}</p>
      <div
        className="footer__square"
        style={
          modeState
            ? { backgroundColor: "#090808" }
            : { backgroundColor: "#FF3737" }
        }
      />
      <div
        className={`footer__triangle footer__triangle_${
          modeState ? "red" : "purple"
        }`}
      />
    </footer>
  );
}
