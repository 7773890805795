import SkillsCard from "../../components/skills-table/skills-card";
import { skills } from "../../utils/constants";

export default function Skills({ modeState }) {
  return (
    <section className="skills">
      <h2
        className={`skills__title ${
          modeState ? "skills__title_red" : "skills__title_yellow"
        }`}
      >
        Skills
      </h2>
      <p className="skills__description">
        Here is the pereodic table of my skills. Hover to see them colored!
      </p>
      {/* Components renders in JS */}
      <div className="skills__table">
        <div className="skills__spacer skills__spacer_top"></div>
        <div className="skills__spacer skills__spacer_middle"></div>
        <div className="skills__spacer skills__spacer_bottom"></div>
        <div className="skills__spacer skills__spacer_left-square"></div>
        <div className="skills__spacer skills__spacer_right-square"></div>
        {skills.map((card) => {
          return (
            <SkillsCard
              key={card.key}
              name={card.name}
              url={card.url}
              image={card.image}
              theme={modeState}
            />
          );
        })}
      </div>
      <p className="skills__undertext">
        SQL | NOSQL | MYSQL | UNIT TESTING | OOP | UI/UX| REST APIS | AGILE |
        ALGORITHMS
      </p>
    </section>
  );
}
