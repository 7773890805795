import _ from "lodash";

export default function ModalProject({ data }) {
  return (
    <div className="modal__content modal-project">
      <a
        className="modal-project__image"
        style={{ backgroundImage: `url(${data.image})` }}
        href={data.projectUrl}
        target="_blank"
        rel="noreferrer"
      >
        Click to the project
      </a>
      <div className="modal-project__content">
        <h3 className="modal-project__title">{data.title}</h3>
        <p className="modal-project__description">{data.description}</p>
        <ul className="modal-project__tech-list">
          {data.tech !== undefined &&
            data.tech.map((tech) => {
              return (
                <li
                  className="modal-project__tech-item"
                  key={_.uniqueId("tech_")}
                >
                  <img
                    className="modal-project__tech-image"
                    src={tech.image}
                    alt={tech.name}
                  />
                </li>
              );
            })}
        </ul>
        <div className="modal-project__links">
          <a
            className={`modal-project__link ${
              data.modeState
                ? "modal-project__link_dark"
                : "modal-project__link_light"
            }`}
            href={data.projectUrl}
            target="_blank"
            rel="noreferrer"
          >
            &#60; Project
          </a>
          {data.githubUrl && (
            <>
              <span> | </span>
              <a
                className={`modal-project__link ${
                  data.modeState
                    ? "modal-project__link_dark"
                    : "modal-project__link_light"
                }`}
                href={data.githubUrl}
                target="_blank"
                rel="noreferrer"
              >
                Github &#62;
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
