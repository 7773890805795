import JavaScriptIcon from "../images/skills/javascript.png";
import HtmlIcon from "../images/skills/html.png";
import CssIcon from "../images/skills/css.png";
import BootStrapsIcon from "../images/skills/bootstraps.png";
import ExpressIcon from "../images/skills/express.png";
import FigmaIcon from "../images/skills/figma.png";
import GHIcon from "../images/skills/github.png";
import GCIcon from "../images/skills/google-cloud.png";
import JestIcon from "../images/skills/jest.png";
import JWTIcon from "../images/skills/jwt.png";
import MongoIcon from "../images/skills/mongoDB.png";
import NGINXIcon from "../images/skills/nginx.png";
import NodeIcon from "../images/skills/node.png";
import NPMIcon from "../images/skills/npm.png";
import PostmanIcon from "../images/skills/postman.png";
import ReactIcon from "../images/skills/react.png";
import SCIcon from "../images/skills/styled-component.png";
import WPIcon from "../images/skills/webpack.png";

import LandingPageProjectImg from "../images/projects/from-homeland-to-homeland.jpg";
import PhotoAppProjectImg from "../images/projects/around-the-us.jpg";
import WeatherAppProjectImg from "../images/projects/wtwr.jpg";
import FoodTruckProjectImg from "../images/projects/buckeyecrepes.png";

import LinkedinIcon from "../components/icons/linkedin-icon";
import GithubIcon from "../components/icons/github";
import VkIcon from "../components/icons/vk-icon";
import InstaIcon from "../components/icons/instagram-icon";
import EmailIcon from "../components/icons/email-icon";

/// Array of header title

const headerTitle = [
  "Photographer 📷",
  "Teacher 📚",
  "Gamer 🎮",
  "Musician 🎶",
  "Good Guy 😃",
  "Biker 🚵🏼‍♂️",
  "Player ⚽🏀",
];

/// Array of cards

const skills = [
  {
    key: 1,
    image: JavaScriptIcon,
    url: 2,
    name: "JavaScript",
  },
  {
    key: 2,
    image: HtmlIcon,
    url: 2,
    name: "HTML",
  },
  {
    key: 3,
    image: CssIcon,
    url: 2,
    name: "CSS",
  },
  {
    key: 4,
    image: BootStrapsIcon,
    url: 2,
    name: "Bootstrap",
  },
  {
    key: 5,
    image: ExpressIcon,
    url: 2,
    name: "Express JS",
  },
  {
    key: 6,
    image: FigmaIcon,
    url: 2,
    name: "Figma",
  },
  {
    key: 7,
    image: GHIcon,
    url: 2,
    name: "GitHub",
  },
  {
    key: 8,
    image: GCIcon,
    url: 2,
    name: "Google Cloud",
  },
  {
    key: 9,
    image: JestIcon,
    url: 2,
    name: "Jest",
  },
  {
    key: 10,
    image: JWTIcon,
    url: 2,
    name: "JSON Web Token",
  },
  {
    key: 11,
    image: MongoIcon,
    url: 2,
    name: "MongoDB",
  },
  {
    key: 12,
    image: NGINXIcon,
    url: 2,
    name: "NGINX",
  },
  {
    key: 13,
    image: NodeIcon,
    url: 2,
    name: "Node JS",
  },
  {
    key: 14,
    image: NPMIcon,
    url: 2,
    name: "NPM",
  },
  {
    key: 15,
    image: PostmanIcon,
    url: 2,
    name: "Postman",
  },
  {
    key: 16,
    image: ReactIcon,
    url: 2,
    name: "React",
  },
  {
    key: 17,
    image: SCIcon,
    url: 2,
    name: "Styled-component",
  },
  {
    key: 18,
    image: WPIcon,
    url: 2,
    name: "Webpack",
  },
];

/// Array for Experience section

const experienceArr = [
  {
    id: 1,
    title: "Selet",
    type: "(Seasonal)",
    position: "Programming Teacher | Sport Event Manager",
    content: {
      subtitle: "",
      workList: [
        "Taught kids how to code and get their ideas in life. Designed curriculum for programming courses.",
        "Organized and managed sport events for summer camps.",
        "Cooperated in team of young and ambitious people to give excellent camp experience to kids.",
      ],
    },
  },
  {
    id: 2,
    title: "Yandex",
    type: "(Part/Full Time)",
    position: "Full Stack Engineer",
    content: {
      subtitle: "",
      workList: [
        "Developing and implementing new features for web applications using HTML/CSS, JavaScript, React, and Redux.",
        "Writing server-side code using Node.js and SQL to build scalable and efficient back-end systems.",
        "Conducting code reviews and ensuring code quality and maintainability.",
        "Troubleshooting technical issues and providing timely solutions to maintain smooth application operation.",
        "Collaborating closely with cross-functional teams including UX/UI designers, product managers, and QA engineers to deliver high-quality products on time.",
        "Participating in daily stand-up meetings, sprint planning, and retrospectives to ensure effective communication and progress tracking.",
        "Learning and adapting to new technologies and methodologies to stay up-to-date with industry trends and best practices.",
      ],
    },
  },
  {
    id: 3,
    title: "Mu Methods",
    type: "(Contract)",
    position: "Front End Engineer | React Engineer",
    content: {
      workList: [
        "Developing reusable components to enhance the overall efficiency of the project.",
        "Implementing automated testing utilizing react-testing-library to ensure the application's robustness.",
        "Employing Storybooks to provide a comprehensive and detailed overview of the component development process",
        "Conducting stand-up style code reviews to ensure the highest level of code quality and consistency within the team",
      ],
    },
  },
];

/// Array for Project section

const projectsArr = [
  {
    id: "what-to-wear",
    image: WeatherAppProjectImg,
    title: "What to Wear | Weather App",
    description:
      "Do you often wonder what to wear? Look no further than this website! It offers a range of different style suggestions for current weather. The website is a fully functional React application, complete with a connected backend coded on Express. Users can easily register and login to post their own style, and centralized error handler is in place to notify you if something goes wrong. So, step inside and fit to the weather",
    tech: [
      { name: "Google Cloud", image: GCIcon },
      { name: "JSON Web Token", image: JWTIcon },
      { name: "Mongo DB", image: MongoIcon },
      { name: "Express JS", image: ExpressIcon },
      { name: "JavaScript", image: JavaScriptIcon },
      { name: "Figma", image: FigmaIcon },
      { name: "React", image: ReactIcon },
      { name: "HTML", image: HtmlIcon },
      { name: "CSS", image: CssIcon },
    ],
    projectUrl: "https://kamal-ganiev.github.io/wtwr_frontend/",
    githubUrl: "https://github.com/kamal-ganiev/wtwr_frontend.git",
  },
  {
    id: "around-the-us",
    image: PhotoAppProjectImg,
    title: "Around the U.S | Photo App",
    description:
      "With this application, users are able to create a personal photo collection by uploading photos along with customized captions. To improve the user's overall experience, I incorporated modal windows and implemented client-side form validation behavior.",
    tech: [
      { name: "CSS", image: CssIcon },
      { name: "HTML", image: HtmlIcon },
      { name: "Figma", image: FigmaIcon },
      { name: "JavaScript", image: JavaScriptIcon },
      { name: "Webpack", image: WPIcon },
    ],
    projectUrl: "https://kamal-ganiev.github.io/aroundtheus_demo/",
    githubUrl: "https://github.com/kamal-ganiev/aroundtheus_demo.git",
  },
  {
    id: "from-homeland-to-homeland",
    image: LandingPageProjectImg,
    title: "From Homeland to Homeland | Landing page",
    description:
      "As part of my efforts to optimize user experience across all devices, I embarked on a comprehensive project to integrate media queries into my web page. This initiative was undertaken with the objective of ensuring flawless appearance and functionality, based on a Figma design. The ultimate goal of this project was to achieve optimal responsiveness, thereby enabling seamless adaptability of the page to varying device sizes.",
    tech: [
      { name: "HTML", image: HtmlIcon },
      { name: "CSS", image: CssIcon },
      { name: "Figma", image: FigmaIcon },
    ],
    projectUrl: "https://kamal-ganiev.github.io/from_homeland_to_homeland/",
    svg: "https://github.com/kamal-ganiev/from_homeland_to_homeland.git",
  },
  {
    id: "buckeye-crepes",
    image: FoodTruckProjectImg,
    title: "Buckeye Crepes | Local Foodtruck Website",
    description:
      "Welcome to our exciting project for a local food truck business! Our mission is to create a visually appealing and user-friendly design that embodies the brand's essence. We aim to revolutionize the industry with an intuitive interface featuring our vibrant colors. Our design is also responsive across devices, showcasing our exceptional ability to bring the customer's ideas to life.",
    tech: [
      { name: "CSS", image: CssIcon },
      { name: "HTML", image: HtmlIcon },
      { name: "Figma", image: FigmaIcon },
      { name: "JavaScript", image: JavaScriptIcon },
    ],
    projectUrl: "https://buckeyecrepes.com/",
  },
];

/// Contact links icon array

const footerLinks = [
  {
    Icon: LinkedinIcon,
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/kamal-ganiev/",
  },
  {
    Icon: GithubIcon,
    name: "GitHub",
    url: "https://github.com/kamal-ganiev",
  },
  {
    Icon: VkIcon,
    name: "VK",
    url: "https://vk.com/kganiev1999",
  },
  {
    Icon: InstaIcon,
    name: "Instagram",
    url: "https://www.instagram.com/kamal.ganiev/",
  },
  {
    Icon: EmailIcon,
    name: "Email",
    email: true,
    url: "kamal.ganiev999@gmail.com",
  },
];

/// Navigation link array

const navLinkArray = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Projects",
    link: "/projects",
  },
  {
    name: "Skills",
    link: "/skills",
  },
  {
    name: "Experience",
    link: "/experience",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

export {
  headerTitle,
  skills,
  experienceArr,
  projectsArr,
  footerLinks,
  navLinkArray,
};
