import { useState } from "react";
import Modal from "../../components/modal/modal";
import ModalProject from "../../components/modal/modal-project/modal-project";
import ProjectCard from "../../components/project-card/project-card";
import { projectsArr } from "../../utils/constants";

export default function Projects({ modeState }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  return (
    <section className="projects">
      <h2
        className={`projects__title ${
          modeState ? "projects__title_red" : "projects__title_yellow"
        }`}
      >
        Projects
      </h2>
      <div className="project__content">
        <ul className="projects__list">
          {projectsArr.map((project, index) => {
            return (
              <ProjectCard
                key={index}
                id={project.id}
                image={project.image}
                title={project.title}
                description={project.description}
                tech={project.tech}
                projectUrl={project.projectUrl}
                githubUrl={project.githubUrl}
                index={index + 1}
                mode={modeState}
                clickFunction={() => {
                  setIsOpen(true);
                }}
                setData={(data) => {
                  setModalData(data);
                }}
              />
            );
          })}
        </ul>
      </div>

      <Modal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
        modeState={modeState}
      >
        <ModalProject data={modalData} />
      </Modal>
    </section>
  );
}
