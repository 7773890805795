export default function InstaIcon({ className, color }) {
  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1585 0C13.0794 0 0 13.0915 0 29.1748V70.8415C0 86.9206 13.0915 100 29.1748 100H70.8415C86.9206 100 100 86.9085 100 70.8252V29.1585C100 13.0794 86.9085 0 70.8252 0H29.1585ZM79.1667 16.6667C81.4667 16.6667 83.3333 18.5333 83.3333 20.8333C83.3333 23.1333 81.4667 25 79.1667 25C76.8667 25 75 23.1333 75 20.8333C75 18.5333 76.8667 16.6667 79.1667 16.6667ZM50 25C63.7875 25 75 36.2125 75 50C75 63.7875 63.7875 75 50 75C36.2125 75 25 63.7875 25 50C25 36.2125 36.2125 25 50 25ZM50 33.3333C45.5797 33.3333 41.3405 35.0893 38.2149 38.2149C35.0893 41.3405 33.3333 45.5797 33.3333 50C33.3333 54.4203 35.0893 58.6595 38.2149 61.7851C41.3405 64.9107 45.5797 66.6667 50 66.6667C54.4203 66.6667 58.6595 64.9107 61.7851 61.7851C64.9107 58.6595 66.6667 54.4203 66.6667 50C66.6667 45.5797 64.9107 41.3405 61.7851 38.2149C58.6595 35.0893 54.4203 33.3333 50 33.3333Z"
        fill={color}
      />
    </svg>
  );
}
