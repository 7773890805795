import profileImage from "../../images/portrait.png";
import lightProfileImage from "../../images/portrait_light.png";

export default function Portrait({ modeState }) {
  return (
    <div className="about__portrait portrait">
      <img
        className="portrait__image"
        alt="Portrait of me"
        src={modeState ? profileImage : lightProfileImage}
      />
    </div>
  );
}
