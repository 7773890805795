export default function VkIcon({ className, color }) {
  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 0C20.636 0 0 20.636 0 46C0 71.364 20.636 92 46 92C71.366 92 92 71.364 92 46C92 20.636 71.366 0 46 0ZM65.946 55.07C70.42 59.042 71.35 60.46 71.502 60.702C73.356 63.642 69.446 64 69.446 64H61.206C61.206 64 59.2 64.022 57.486 62.886C54.692 61.056 51.766 57.508 49.71 58.124C47.984 58.64 48 60.972 48 62.966C48 63.68 47.386 64 46 64C44.614 64 44.038 64 43.424 64C38.91 64 34.012 62.48 29.126 57.374C22.214 50.156 16.152 35.616 16.152 35.616C16.152 35.616 15.794 34.884 16.184 34.438C16.626 33.938 17.828 34.002 17.828 34.002L25.818 34C25.818 34 26.57 34.142 27.11 34.522C27.556 34.834 27.804 35.43 27.804 35.43C27.804 35.43 29.146 39.862 30.856 42.688C34.196 48.204 35.75 48.344 36.884 47.75C38.54 46.89 38 41.026 38 41.026C38 41.026 38.074 38.508 37.21 37.386C36.544 36.518 35.27 36.056 34.714 35.984C34.264 35.926 35.016 35.138 35.974 34.688C37.254 34.118 38.996 33.972 42 34C44.338 34.022 45.012 34.162 45.924 34.372C48.682 35.008 48 36.686 48 42.578C48 44.466 47.74 47.12 49.164 48C49.778 48.38 51.882 48.844 55.626 42.764C57.404 39.88 58.818 35.096 58.818 35.096C58.818 35.096 59.11 34.57 59.564 34.31C60.028 34.044 60.014 34.05 60.65 34.05C61.286 34.05 67.664 34 69.064 34C70.462 34 71.774 33.984 72 34.804C72.324 35.982 70.968 40.018 67.532 44.398C61.886 51.586 61.26 50.914 65.946 55.07Z"
        fill={color}
      />
    </svg>
  );
}
