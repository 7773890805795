import { useEffect, useState } from "react";
import shapesDark from "../../images/intro/shapes.png";
import shapesLight from "../../images/intro/shapes__light.png";
import { headerTitle } from "../../utils/constants";

export default function Home({ modeState }) {
  const [text, setText] = useState("Photographer 📷");

  useEffect(() => {
    const interval = setInterval(() => {
      setText(
        (text) =>
          (text = headerTitle[Math.floor(Math.random() * headerTitle.length)])
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <header className="header">
      <div className="header__title">
        <h1
          className={`header__title ${
            modeState ? "header__title_red" : "header__title_purple"
          }`}
        >
          Hello,
        </h1>
        <h1 className="header__title">I'm</h1>
        <h1
          className={`header__title ${
            modeState ? "header__title_red" : "header__title_purple"
          }`}
        >
          Kamal Ganiev,
        </h1>
        <h1 className="header__title">
          Full Stack Web Developer &{" "}
          <span className="header__title-onchange">{text}</span>
        </h1>
      </div>
      <div className="header__icon">
        <img
          className="header__shapes"
          src={modeState ? shapesDark : shapesLight}
          alt="Tower of shapes"
        />
      </div>
    </header>
  );
}
