export default function EmailIcon({ className, color }) {
  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8095 0C10.6873 0 0 10.6873 0 23.8095V76.1905C0 89.3127 10.6873 100 23.8095 100H76.1905C89.3127 100 100 89.3127 100 76.1905V23.8095C100 10.6873 89.3127 0 76.1905 0H23.8095ZM21.4286 28.5714H78.5714C79 28.5714 79.4042 28.6198 79.7852 28.7388L56.3802 52.1205C52.8564 55.6443 47.1203 55.6443 43.5965 52.1205L20.2148 28.7388C20.5958 28.6198 21 28.5714 21.4286 28.5714ZM16.8341 32.1196L34.7377 50L16.8341 67.8804C16.715 67.4994 16.6667 67.0952 16.6667 66.6667V33.3333C16.6667 32.9048 16.715 32.5006 16.8341 32.1196ZM83.1659 32.1196C83.285 32.5006 83.3333 32.9048 83.3333 33.3333V66.6667C83.3333 67.0952 83.285 67.4994 83.1659 67.8804L65.239 50L83.1659 32.1196ZM38.0952 53.3575L40.2158 55.5013C42.9062 58.1918 46.4529 59.5238 49.9767 59.5238C53.5244 59.5238 57.0472 58.1918 59.7377 55.5013L61.8815 53.3575L79.7852 71.2612C79.4042 71.3802 79 71.4286 78.5714 71.4286H21.4286C21 71.4286 20.5958 71.3802 20.2148 71.2612L38.0952 53.3575Z"
        fill={color}
      />
    </svg>
  );
}
