import ExperienceCard from "../../components/experience-card/experience-card";
import { experienceArr } from "../../utils/constants";

export default function Experience({ modeState }) {
  return (
    <section className="experience">
      <h2
        className={`experience__title ${
          modeState ? "experience__title_red" : "experience__title_yellow"
        }`}
      >
        Experience
      </h2>
      <div className="experience__content">
        {experienceArr.map((card, index) => {
          return (
            <ExperienceCard
              key={card.id}
              title={card.title}
              type={card.type}
              position={card.position}
              subtitle={card.content.subtitle}
              list={card.content.workList}
              index={index + 1}
              mode={modeState}
            />
          );
        })}
        <div
          className="experience__line"
          style={
            modeState
              ? { border: "1.5px solid white" }
              : { border: "1.5px solid black" }
          }
        />
      </div>
      <div
        className="experience__footer"
        style={
          modeState
            ? { border: "3px solid #ffffff" }
            : { border: "3px solid #000" }
        }
      >
        <p className="experience__footer-text">
          Looking forward for more challenges{" "}
        </p>
      </div>
      <div
        className={`experience__circle experience__circle_${
          modeState ? "red" : "yellow"
        }`}
      />
      <div className="experience__triangle" />
      <div className="experience__square" />
    </section>
  );
}
