export default function LoadingScreen({ visibility, mode }) {
  return (
    <div
      className={visibility ? "loading-screen" : "loading-screen_invisible"}
      style={
        mode
          ? { background: "var(--dark-background)" }
          : { background: "var(--light-background)" }
      }
    >
      <div className="loading-screen__square" />
      <div className="loading-screen__round" />
      <div className="loading-screen__triangle" />
    </div>
  );
}
