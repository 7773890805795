export default function FooterLink({ Icon, url, email, mode }) {
  return (
    <li className="footer__link">
      <a
        className="footer__link-item"
        href={email ? `mailto:${url}` : url}
        target="_blank"
        rel="noreferrer"
      >
        <Icon
          className="footer__link-icon"
          color={mode ? "#FBFBFB" : "#4e1bb4"}
        />
      </a>
    </li>
  );
}
