import { useRef, useState } from "react";
import NavItems from "../nav-items/nav-items";
import { navLinkArray } from "../../utils/constants";

export default function NavBar({ theme, setTheme }) {
  const [isMenuUp, setIsMenuUp] = useState(false);

  const menuCheckbox = useRef();
  return (
    <div>
      <div
        className="header__menu menu"
        style={theme ? { color: "white" } : { color: "black" }}
      >
        <input
          className="menu__input"
          ref={menuCheckbox}
          type="checkbox"
          id="menu_checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setIsMenuUp(true);
            } else {
              setIsMenuUp(false);
            }
          }}
        />
        <label className="menu__burger" htmlFor="menu_checkbox">
          <div
            className={`menu__line ${
              theme ? "menu__line_dark" : "menu__line_light"
            }`}
          />
          <div
            className={`menu__line ${
              theme ? "menu__line_dark" : "menu__line_light"
            }`}
          />
          <div
            className={`menu__line ${
              theme ? "menu__line_dark" : "menu__line_light"
            }`}
          />
        </label>
      </div>
      <NavItems
        theme={theme}
        setTheme={setTheme}
        navItemsList={navLinkArray}
        setClose={setIsMenuUp}
        isClose={isMenuUp}
        menuRef={menuCheckbox}
        className="nav__item"
      />
    </div>
  );
}
