import Portrait from "../../components/portrait/Portrait";

export default function About({ scrollRef, modeState }) {
  return (
    <section className="about" ref={scrollRef}>
      <h2
        className={`about__title ${
          modeState ? "about__title_red" : "about__title_yellow"
        }`}
      >
        About me
      </h2>
      <p className="about__description">
        I'm a driven 23-year-old Frontend Engineer with an insatiable thirst for
        knowledge and a passion for teaching others. Whether I'm working with
        children or collaborating with a team of ambitious peers, my experience
        has equipped me with invaluable communication skills, teamwork prowess,
        and a relentless drive to succeed. With an unwavering love for coding
        and an innate ability to innovate, I'm confident in my ability to solve
        any problem that comes my way. Let's create something incredible
        together!
      </p>
      <div className="about__image">
        <Portrait modeState={modeState} />
      </div>
      <div
        className={` ${
          modeState ? "about__rectangle" : "about__rectangle_hidden"
        }`}
      />
    </section>
  );
}
